var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"aa1876113baba3ee2336a13418d1ca9123afd631"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { sentryIgnoreErrorsList } from "@pepdirect/v3/constants";
import {
  environment,
  isProductionReady,
  sentryDSN,
  sentryEnvironment,
} from "config";

const releaseId = process.env.SENTRY_RELEASE;

Sentry.init({
  enabled: isProductionReady,
  dsn: sentryDSN || "",
  environment: sentryEnvironment || environment,
  release: releaseId,
  allowUrls: [
    /https?:\/\/assets\.?production\.decovostatic\.com/,
    /https?:\/\/assets\.?staging\.decovostatic\.com/,
  ],
  // https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/filtering/
  ignoreErrors: sentryIgnoreErrorsList,
  enableTracing: false,
  tracesSampleRate: 0,
  beforeSend(event, hint) {
    try {
      const frames = event?.exception?.values?.[0]?.stacktrace?.frames || [];

      if (frames?.length) {
        const excludedFilenames = [`<anonymous>`, `/gtm.js`];
        const excludedFilenameAtIndex = (i: number) =>
          excludedFilenames.includes(frames?.[i]?.filename || "");

        if (excludedFilenameAtIndex(0)) return null;

        // catches excludedFilenames from injected anonymous
        // gtm script matching this common sentry error pattern
        if (frames.length === 2 && excludedFilenameAtIndex(1)) return null;
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}

    return event;
  },
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "fetch" && hint?.input[1]?.body) {
      return {
        ...breadcrumb,
        data: {
          ...breadcrumb.data,
          operationName: JSON.parse(hint?.input[1].body).operationName,
        },
      };
    }
    return breadcrumb;
  },
});

Sentry.configureScope((scope) => scope.setLevel("error"));
